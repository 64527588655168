import React, { useEffect, useState, useCallback } from 'react';
import { Button, Typography, Box } from '@mui/material';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { downloadData, uploadData } from 'aws-amplify/storage';
import '../App.css';
import correctImage from '../marujirushi.svg';
import incorrectImage from '../batsujirushi.svg';

const Quiz = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const username = user ? user.username : null;

  const [words, setWords] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [showNextButton, setShowNextButton] = useState(false);
  const [showFeedbackIcon, setShowFeedbackIcon] = useState(false);
  const [quizCount, setQuizCount] = useState(0);

  const shuffleArray = useCallback((array) => {
    return array.sort(() => Math.random() - 0.5);
  }, []);

  const setNewQuestion = useCallback(
    async (wordList) => {
      const statsKey = 'public/quiz-stats.json';
      let stats = {};
  
      try {
        const downloadStats = await downloadData({ path: statsKey }).result;
        const statsText = await downloadStats.body.text();
        const allStats = JSON.parse(statsText);
  
        // 現在のユーザーのデータのみ取得
        stats = allStats[username] || {};
      } catch (error) {
        console.warn('No stats data found for this user, defaulting to random selection.');
      }
  
      // 得意分野を特定し除外するロジックをそのまま利用
      const strongestCategory = Object.entries(stats)
        .reduce((acc, [key, value]) => {
          const accuracy = value.correct / value.total;
          return accuracy > acc.accuracy ? { category: key, accuracy } : acc;
        }, { category: null, accuracy: -1 })
        .category;
  
      const filteredWords = wordList.filter(word => word.category !== strongestCategory);
  
      const correctWord = filteredWords[Math.floor(Math.random() * filteredWords.length)];
      const options = [correctWord];
  
      while (options.length < 4) {
        const randomWord = wordList[Math.floor(Math.random() * wordList.length)];
        if (!options.includes(randomWord)) options.push(randomWord);
      }
  
      setCurrentQuestion(correctWord);
      setOptions(shuffleArray(options));
      setFeedback(null);
      setSelectedOption(null);
      setShowNextButton(false);
      setShowFeedbackIcon(false);
    },
    [shuffleArray, username]
  );

  useEffect(() => {
    const fetchWords = async () => {
      try {
        const downloadResult = await downloadData({
          path: 'word-list.json',
          options: { cacheControl: 'no-cache' },
        }).result;

        const wordsText = await downloadResult.body.text();
        const wordList = JSON.parse(wordsText);
        setWords(wordList);
        setNewQuestion(wordList);
      } catch (error) {
        console.error('Error fetching words:', error);
      }
    };

    fetchWords();
  }, [setNewQuestion]);

  const handleOptionClick = (option) => {
    if (selectedOption) return; // 既に選択されている場合は何もしない
    setSelectedOption(option);
    const isCorrect = option.word === currentQuestion.word;
    setFeedback(isCorrect);
    setShowNextButton(true);
    setShowFeedbackIcon(true);
    setTimeout(() => setShowFeedbackIcon(false), 500);

    if (user) {
      saveResult(option, isCorrect);
    }
  };

  const saveResult = async (selectedOption, isCorrect) => {
    const result = {
      username,
      question: currentQuestion.meaning,
      correctAnswer: currentQuestion.word,
      selectedAnswer: selectedOption.word,
      isCorrect,
      timestamp: new Date().toISOString(),
      options: options.map((opt) => ({
        word: opt.word,
        category: opt.category,
      })),
    };

    try {
      const resultsKey = 'public/quiz-results.json';
      let results = [];

      try {
        const downloadResults = await downloadData({ path: resultsKey }).result;
        const resultsText = await downloadResults.body.text();
        results = JSON.parse(resultsText);
      } catch (error) {
        console.warn('No existing results found, starting a new file.');
      }

      results.push(result);

      await uploadData({
        path: resultsKey,
        data: new Blob([JSON.stringify(results)], { type: 'application/json' }),
        options: { contentType: 'application/json' },
      }).result;
    } catch (error) {
      console.error('Error saving result:', error);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={2} className="quiz-container">
      <Typography variant="h5" gutterBottom className="instruction-text">
        表示されている意味の英単語を選んでください
      </Typography>
      {currentQuestion && (
        <>
          <Typography variant="h6" gutterBottom className="question-word">
            {currentQuestion.meaning}
          </Typography>
          <Box position="relative" className="options-wrapper">
            {showFeedbackIcon && (
              <div className="feedback-icon">
                <img
                  src={feedback ? correctImage : incorrectImage}
                  alt={feedback ? 'Correct' : 'Incorrect'}
                  className="feedback-image"
                />
              </div>
            )}
            <Box mt={2} display="flex" flexDirection="column" alignItems="center" gap={1} className="options-container">
              {options.map((option, index) => (
                <Button
                  key={index}
                  variant="contained"
                  color="primary"
                  onClick={() => handleOptionClick(option)}
                  fullWidth
                  className={`option-button ${selectedOption === option ? 'selected' : ''}`}
                  disabled={selectedOption !== null}
                  sx={{ textTransform: 'none' }} // 大文字変換を無効化
                >
                  {option.word.toLowerCase()}
                </Button>
              ))}
            </Box>
          </Box>
          {selectedOption && (
            <Box mt={2} className={`feedback ${feedback ? 'correct' : 'incorrect'}`}>
              <Typography variant="body1">
                {selectedOption.example} ({selectedOption.example_jp})
              </Typography>
            </Box>
          )}
          {showNextButton && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setNewQuestion(words)}
              className="next-button"
            >
              NEXT QUIZ
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default Quiz;
