// src/components/Search.js
import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from '@mui/material';
import { downloadData } from 'aws-amplify/storage';

const Search = ({ username }) => {
  const [wordSearch, setWordSearch] = useState('');
  const [meaningSearch, setMeaningSearch] = useState('');
  const [showIncorrectOnly, setShowIncorrectOnly] = useState(false);
  const [selectedPartOfSpeech, setSelectedPartOfSpeech] = useState('all');
  const [searchResults, setSearchResults] = useState([]);
  const [allWords, setAllWords] = useState([]);
  const [incorrectWords, setIncorrectWords] = useState([]);

  useEffect(() => {
    fetchWords();
  }, []);

  useEffect(() => {
    fetchIncorrectWords();
  }, [username]);

  const fetchWords = useCallback(async () => {
    try {
      const downloadResult = await downloadData({
        path: 'word-list.json',
        options: { cacheControl: 'no-cache' },
      }).result;

      const wordsText = await downloadResult.body.text();
      setAllWords(JSON.parse(wordsText));
    } catch (error) {
      console.error('Error fetching words:', error);
    }
  }, []);

  const fetchIncorrectWords = useCallback(async () => {
    try {
      const downloadResult = await downloadData({
        path: 'public/quiz-results.json',
        options: { cacheControl: 'no-cache' },
      }).result;

      const resultsText = await downloadResult.body.text();
      const results = JSON.parse(resultsText);
      const userIncorrectWords = results
        .filter((result) => result.username === username && !result.isCorrect)
        .map((result) => result.correctAnswer);
      setIncorrectWords(userIncorrectWords);
    } catch (error) {
      console.error('Error fetching incorrect words:', error);
    }
  }, [username]);

  const handleSearch = () => {
    let filteredResults = allWords;

    if (showIncorrectOnly) {
      filteredResults = filteredResults.filter((word) => incorrectWords.includes(word.word));
    }

    if (selectedPartOfSpeech !== 'all') {
      filteredResults = filteredResults.filter((word) => word.category === selectedPartOfSpeech);
    }

    if (wordSearch) {
      filteredResults = filteredResults.filter((word) =>
        word.word.toLowerCase().includes(wordSearch.toLowerCase())
      );
    }

    if (meaningSearch) {
      filteredResults = filteredResults.filter((word) =>
        word.meaning.toLowerCase().includes(meaningSearch.toLowerCase())
      );
    }

    setSearchResults(filteredResults);
  };

  const handleClear = () => {
    setWordSearch('');
    setMeaningSearch('');
    setShowIncorrectOnly(false);
    setSelectedPartOfSpeech('all');
    setSearchResults([]);
  };

  const partsOfSpeech = [
    '名詞',
    '動詞',
    '形容詞',
    '副詞',
    '代名詞',
    '前置詞',
    '接続詞',
    '感嘆詞',
    '接続句',
    '動詞句',
    '形容詞句',
    '副詞句',
    '前置詞句',
    '接続詞句'
  ];

  return (
    <div className="search-container"> {/* クラスを適用 */}
      <Typography variant="h4" gutterBottom>
        <span className="icon">🔍</span>
        <span className="text">検索機能</span>
      </Typography>

      <TextField
        label="Search by word（英語）"
        value={wordSearch}
        onChange={(e) => setWordSearch(e.target.value)}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Search by meaning（日本語）"
        value={meaningSearch}
        onChange={(e) => setMeaningSearch(e.target.value)}
        fullWidth
        margin="normal"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={showIncorrectOnly}
            onChange={(e) => setShowIncorrectOnly(e.target.checked)}
          />
        }
        label="間違えた回答の中から検索する"
      />

      <Box sx={{ my: 2 }}>
        <Button
          variant={selectedPartOfSpeech === 'all' ? 'contained' : 'outlined'}
          onClick={() => setSelectedPartOfSpeech('all')}
          sx={{ mr: 1, mb: 1 }}
        >
          All
        </Button>
        {partsOfSpeech.map((pos) => (
          <Button
            key={pos}
            variant={selectedPartOfSpeech === pos ? 'contained' : 'outlined'}
            onClick={() => setSelectedPartOfSpeech(pos)}
            sx={{ mr: 1, mb: 1 }}
          >
            {pos}
          </Button>
        ))}
      </Box>

      <Button variant="contained" onClick={handleSearch} sx={{ mr: 1 }}>
        Search
      </Button>
      <Button variant="outlined" onClick={handleClear}>
        Clear
      </Button>

      {searchResults.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Word</TableCell>
                <TableCell>Meaning</TableCell>
                <TableCell>Example</TableCell>
                <TableCell>Example (JP)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchResults.map((word, index) => (
                <TableRow key={index}>
                  <TableCell>{word.word}</TableCell>
                  <TableCell>{word.meaning}</TableCell>
                  <TableCell>{word.example}</TableCell>
                  <TableCell>{word.example_jp}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default Search;
