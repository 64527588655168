// src/pages/Terms.js
import React from 'react';

const Terms = () => {
  return (
    <div className="content">
      <h1>利用規約</h1>
      <p>
      本利用規約（以下、「本規約」といいます。）は、「英語365単語帳」（以下、「本サービス」といいます。）を運営する当社が、本サービスを利用するすべての登録ユーザー（以下、「ユーザー」といいます。）との間で適用されます。本サービスをご利用いただく際には、本規約の内容に同意いただいたものとみなします。
    </p>

    <h2>第1条（適用範囲）</h2>
    <ul>
      <li>本規約は、本サービスに関する一切の利用に適用されます。</li>
      <li>当社は、本規約のほか、本サービスの利用に関して必要なルールやガイドラインを設ける場合があります。それらは本規約の一部を構成するものとします。</li>
      <li>本規約の内容が特定のルールやガイドラインと矛盾する場合、特段の定めがない限りルールやガイドラインが優先されます。</li>
    </ul>

    <h2>第2条（利用登録）</h2>
    <p>本サービスの利用を希望する場合、ユーザーは当社が定める方法で利用登録を行い、当社がその登録を承認することで利用が開始されます。登録には、ユーザーの有効なメールアドレスが必要です。</p>
    <ul>
      <li>登録申請内容に虚偽があった場合</li>
      <li>過去に本規約に違反した経歴がある場合</li>
      <li>その他、当社が不適切と判断する場合</li>
    </ul>
    <p>利用登録が完了した場合、本規約が適用され、ユーザーはその遵守義務を負うものとします。</p>

    <h2>第3条（アカウント管理）</h2>
    <p>
      ユーザーは、登録したメールアドレスおよびパスワードを自己責任で適切に管理するものとします。第三者に譲渡、共有、または貸与することは禁止されています。
    </p>
    <p>登録情報を利用してなされた行為は、すべて当該ユーザーの行為とみなします。これにより生じた損害について、当社は一切の責任を負いません。</p>

    <h2>第4条（禁止事項）</h2>
    <ul>
      <li>法令または公序良俗に違反する行為</li>
      <li>本サービスの運営を妨害する行為</li>
      <li>他のユーザーや第三者の権利を侵害する行為</li>
      <li>不正アクセスまたはその試行行為</li>
      <li>その他、当社が不適切と判断する行為</li>
    </ul>

    <h2>第5条（免責事項）</h2>
    <p>
      当社は、本サービスの内容について、完全性、正確性、有用性を保証しません。提供する情報に基づき被った損害について、当社は一切の責任を負いません。
    </p>
    <p>サービスの中断、変更、または停止により生じた損害についても、同様に責任を負いません。</p>

    <h2>第6条（サービスの変更および終了）</h2>
    <p>当社は、予告なく本サービスの内容変更または提供終了を行うことがあります。当該変更や終了により、ユーザーに損害が生じた場合でも当社は責任を負いません。</p>

    <h2>第7条（規約の変更）</h2>
    <p>当社は、本規約を必要に応じて変更できるものとします。変更後は、ウェブサイト上での告知またはユーザーへの通知をもって適用されます。</p>

    <div class="spacer"></div>

    <div class="issued-date">
        制定日: 令和6年11月17日
    </div>
    
    </div>
    
  );
};

export default Terms;
