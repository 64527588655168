// src/components/MainMenu.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react'; // 追加
import { Typography } from '@mui/material';
import '../App.css'; // CSSファイルをインポート

const MainMenu = () => {
  const { user } = useAuthenticator((context) => [context.user]); // 認証状態を取得

  return (
    <div className="main-menu">
      <main className="content">
        <Link to="/quiz" className="quiz-button">
          <div className="quiz-button-inner">
            <span className="icon">🎓</span>
            <span className="text">クイズを始める　</span>
            <span className="icon">◀</span>
          </div>
        </Link>

        <div className="features-grid">
          {/* 認証ユーザーのみ表示されるリンク */}
          {user && (
            <>
              <Link to="/results" className="feature-card">
                <span className="icon">📊</span>
                <span className="text">テスト結果の推移</span>
              </Link>
              <Link to="/radar-chart" className="feature-card">
                <span className="icon">📈</span>
                <span className="text">レーダーチャートの表示</span>
              </Link>
              <Link to="/search" className="feature-card">
                <span className="icon">🔍</span>
                <span className="text">検索機能</span>
              </Link>
            </>
          )}

          {/* 未認証ユーザー向けのメッセージ */}
          {!user && (
            <div className="unauthenticated-message">
              <p>より多くの機能を利用するには、サインインしてください。</p>
              <Link to="/login" className="login-button">
              サインイン/無料登録
              </Link>

              <Typography 
                variant="body2" 
                style={{ 
                  marginTop: '1rem', 
                  textAlign: 'center', 
                  color: '#666',
                  lineHeight: '1.6'
                }}
              >
                ユーザーネームとパスワードのみ！<br />
                あなたの英語学習が加速します！ 🚀🚀🚀<br /><br />

                ✨ 単語学習の進捗管理<br />
                📊 テスト結果の推移グラフ<br />
                🎯 レーダーチャートで弱点把握<br />
                🔍 便利な検索機能<br /><br />

                これらの機能が英語力をどんどん加速させる！<br />
                スキマ時間で最大の効果をLet's enjoy studying English！ 😊<br /><br />

                10秒登録で新しい学習体験を始めよう🌟<br />
              </Typography>
              <Link to="/login" className="login-button">
              サインイン/無料登録
              </Link>

            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default MainMenu;
