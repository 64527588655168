// src/components/ContactUs.js
import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [submissionStatus, setSubmissionStatus] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // バックエンドのエンドポイントURLを指定します
      const response = await axios.post('https://your-api-endpoint.amazonaws.com/prod/contact', formData);
      if (response.status === 200) {
        setSubmissionStatus('success');
        setFormData({ name: '', email: '', message: '' });
      } else {
        setSubmissionStatus('error');
      }
    } catch (error) {
      console.error('Error submitting contact form:', error);
      setSubmissionStatus('error');
    }
  };

  return (
    <Box maxWidth={600} mx="auto" mt={4} p={2} className="contact-form">
      <Typography variant="h5" gutterBottom>
        お問い合わせ
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="お名前"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="メールアドレス"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="メッセージ"
          name="message"
          value={formData.message}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          multiline
          rows={4}
        />
        <Button variant="contained" color="primary" type="submit" fullWidth style={{ marginTop: '16px' }}>
          送信
        </Button>
      </form>
      {submissionStatus === 'success' && (
        <Typography color="primary" style={{ marginTop: '16px' }}>
          お問い合わせありがとうございます。メッセージが送信されました。
        </Typography>
      )}
      {submissionStatus === 'error' && (
        <Typography color="error" style={{ marginTop: '16px' }}>
          送信中にエラーが発生しました。後ほど再度お試しください。
        </Typography>
      )}
    </Box>
  );
};

export default ContactUs;
