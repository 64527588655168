// src/components/AuthPage.js

// importに useAuthenticator を追加
import React from 'react';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import '@aws-amplify/ui-react/styles.css';

// 日本語翻訳の設定
I18n.putVocabularies({
  ja: {
    'Sign Up': 'アカウント登録',
    'Sign In': 'ログイン',
    'Sign Out': 'ログアウト',
    'Username': 'ユーザー名',
    'Password': 'パスワード',
    'Confirm Password': 'パスワード（確認）',
    'Please confirm your Password': 'パスワードを再入力してください',
    'Create Account': 'アカウントを作成',
    'Have an account?': 'アカウントをお持ちの方',
    'Sign in': 'ログイン',
    'Forgot your password?': 'パスワードをお忘れの方',
    'Reset Password': 'パスワードをリセット',
    'Enter your username': 'ユーザー名を入力してください',
    'Back to Sign In': 'ログインに戻る',
    'User already exists': 'このユーザー名は既に使用されています',
    'Incorrect username or password.': 'ユーザー名またはパスワードが正しくありません',
    'User does not exist.': 'ユーザーが存在しません',
  }
});

const theme = {
  name: 'auth-theme',
  tokens: {
    colors: {
      brand: {
        primary: {
          '10': '#f3f4f6',
          '80': '#2c3e50',
          '90': '#34495e',
          '100': '#2c3e50'
        },
      },
    },
    borderWidths: {
      small: '2px',
      medium: '4px',
      large: '8px',
    },
    radii: {
      small: '4px',
      medium: '8px',
      large: '12px',
    },
  },
  components: {
    button: {
      primary: {
        backgroundColor: '#2c3e50',
        _hover: {
          backgroundColor: '#34495e',
        },
      },
    },
    authenticator: {
      maxWidth: '400px',
      margin: '0 auto',
      padding: '1rem',
    },
  },
};

const AuthPage = () => {
  const navigate = useNavigate();

  // サインイン状態を監視するカスタムフック
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  // authStatusが変更されたときに実行
  React.useEffect(() => {
    if (authStatus === 'authenticated') {
      navigate('/');
    }
  }, [authStatus, navigate]);


  // フォームフィールドの設定
  const formFields = {
    signUp: {
      username: {
        placeholder: 'ユーザー名を入力',
        isRequired: true,
        label: 'ユーザー名',
      },
      password: {
        placeholder: 'パスワードを入力',
        isRequired: true,
        label: 'パスワード',
      },
      confirm_password: {
        placeholder: 'パスワードを再入力',
        isRequired: true,
        label: 'パスワード（確認）',
      }
    },
    signIn: {
      username: {
        placeholder: 'ユーザー名を入力',
        isRequired: true,
        label: 'ユーザー名',
      },
      password: {
        placeholder: 'パスワードを入力',
        isRequired: true,
        label: 'パスワード',
      },
    },
  };

  // カスタムコンポーネント
  const components = {
    SignUp: {
      FormFields() {
        return (
          <Authenticator.SignUp.FormFields />
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        // 確認コード画面を表示しない
        const navigate = useNavigate();
        React.useEffect(() => {
          navigate('/');
        }, [navigate]);
        return null;
      },
    },
  };

  // バリデーション関数
  const services = {
    async validateCustomSignUp(formData) {
      const errors = {};
      
      if (!formData.username) {
        errors.username = 'ユーザー名は必須です';
      } else if (formData.username.length < 3) {
        errors.username = 'ユーザー名は3文字以上で入力してください';
      }

      if (!formData.password) {
        errors.password = 'パスワードは必須です';
      } else if (formData.password.length < 8) {
        errors.password = 'パスワードは8文字以上で入力してください';
      }

      return errors;
    }
  };

  return (
    <div style={{ 
      maxWidth: '400px', 
      margin: '2rem auto', 
      padding: '0 1rem' 
    }}>
      <Authenticator
        initialState="signUp"
        components={components}
        services={services}
        formFields={formFields}
        signUpAttributes={[
          'username'
        ]}
        variation="modal"
        hideSignUp={false}
        theme={theme}
      />
    </div>
  );
};

export default AuthPage;