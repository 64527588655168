// src/pages/Cookies.js
import React from 'react';

const Cookies = () => {
  return (
    <div className="content">
      <h1>クッキーポリシー</h1>
      <h2>第1条（クッキーの利用目的）</h2>
            <ul>
            <li>サービスの利便性向上</li>
            <li>アクセス解析および利用状況の把握</li>
            <li>広告配信の最適化</li>
            </ul>

    <h2>第2条（クッキーの管理）</h2>
            <p>ユーザーは、ブラウザの設定を変更することでクッキーを無効にすることができます。ただし、一部機能が制限される場合があります。</p>
    <div class="spacer"></div>

    <div class="issued-date">
        制定日: 令和6年11月17日
    </div>
    </div>
  );
};

export default Cookies;
