// src/pages/Privacy.js
import React from 'react';

const Privacy = () => {
  return (
    <div className="content">
      <h1>プライバシーポリシー</h1>
      <p>
      「英語365単語帳」（以下、「本サービス」といいます。）は、ユーザーの個人情報を適切に取り扱うため、以下のプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
    </p>

    <h2>第1条（収集する情報の内容）</h2>
    <ul>
      <li>利用登録時に提供されるメールアドレス</li>
      <li>クッキーを利用して取得される端末情報およびアクセス履歴</li>
    </ul>

    <h2>第2条（情報の利用目的）</h2>
        <ul>
        <li>本サービスを提供・運営するため</li>
        <li>ユーザーの不正利用を防止するため</li>
        <li>メールによる連絡やお知らせを送るため</li>
        <li>サービス改善や運営向上のため</li>
        </ul>
            <div class="spacer"></div>

            <div class="issued-date">
                制定日: 令和6年11月17日
            </div>
    </div>
  );
};

export default Privacy;
