import React, { useEffect, useState } from 'react';
import '../App.css';
import { Line } from 'react-chartjs-2';
import { downloadData } from 'aws-amplify/storage';
import { OpenAI } from 'openai';
import {
  Chart as ChartJS,
  BarController,
  BarElement,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  BarController,
  BarElement,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend
);

// OpenAI設定
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

// メッセージキャッシュ
const messageCache = new Map();
const CACHE_DURATION = 1000 * 60 * 5; // 5分

const Results = ({ username }) => {
  const [chartData, setChartData] = useState(null);
  const [message, setMessage] = useState('');
  const [analysisData, setAnalysisData] = useState(null);

  // データ分析とメッセージ生成
  const generateMotivationalMessage = async (analysisData) => {
    try {
      const cacheKey = `${username}-${analysisData.dates[analysisData.dates.length - 1]}`;
      const cachedResult = messageCache.get(cacheKey);
      
      if (cachedResult && (Date.now() - cachedResult.timestamp) < CACHE_DURATION) {
        return cachedResult.message;
      }

      const response = await openai.chat.completions.create({
        model: "gpt-3.5-turbo-instruct",
        messages: [
          {
            role: "system",
            content: `あなたは英語学習のデータアナリストです。学習者の進捗データを分析し、
            具体的な改善アドバイスとモチベーションアップのメッセージを提供してください。
            グラフの特徴を踏まえた具体的なコメントを心がけてください。`
          },
          {
            role: "user",
            content: `
              以下の学習データを分析し、具体的なアドバイスを含むメッセージを生成してください：
              
              ユーザー: ${username}
              学習期間: ${analysisData.studyDays}日間
              1日あたりの平均問題数: ${analysisData.averageQuestionsPerDay}問
              直近5日間の平均正答率: ${analysisData.averageRecentAccuracy}%
              学習の継続性: ${analysisData.consistency}
              成績トレンド: ${analysisData.isImproving ? '上昇傾向' : '改善の余地あり'}
              最高正答率: ${analysisData.highestAccuracy}%
              
              分析のポイント：
              1. 時系列での成績の変化
              2. 学習頻度と問題数の関係
              3. 直近の傾向
              4. 最高正答率との比較
              
              メッセージの要件：
              - 200文字程度で簡潔に
              - グラフの特徴を1つ具体的に言及
              - 現在の学習パターンを基にした具体的なアドバイスを含める
              - 学習意欲を高める表現を使用
              - 数値を用いた具体的な言及を含める
            `
          }
        ],
        max_tokens: 200,
        temperature: 0.7,
      });

      const message = response.choices[0].message.content;
      messageCache.set(cacheKey, { message, timestamp: Date.now() });
      return message;

    } catch (error) {
      console.warn('API error, using fallback message:', error);
      return `${username}さん、${analysisData.studyDays}日間で合計${analysisData.totalQuestions}問に挑戦していますね！
        ${analysisData.isImproving ? '正答率も上昇傾向にあり、着実な成長が見られます。' : '継続は力です。'} 
        1日${analysisData.averageQuestionsPerDay}問のペースを維持しながら、さらなる高みを目指しましょう！`;
    }
  };

  // データ取得と分析
  useEffect(() => {
    const fetchResults = async () => {
      try {
        const downloadResult = await downloadData({
          path: 'public/quiz-results.json',
          options: { cacheControl: 'no-cache' },
        }).result;

        const resultsText = await downloadResult.body.text();
        const results = JSON.parse(resultsText);

        const userResults = results.filter((result) => result.username === username);
        const dailyResults = userResults.reduce((acc, result) => {
          const date = new Date(result.timestamp).toLocaleDateString();
          if (!acc[date]) {
            acc[date] = { correct: 0, total: 0 };
          }
          if (result.isCorrect) acc[date].correct += 1;
          acc[date].total += 1;
          return acc;
        }, {});

        const dates = Object.keys(dailyResults).sort();
        const correctRates = dates.map(
          (date) => (dailyResults[date].correct / dailyResults[date].total) * 100
        );
        const testCounts = dates.map((date) => dailyResults[date].total);

        // 分析データの作成
        const analysisData = {
          dates,
          studyDays: dates.length,
          totalQuestions: testCounts.reduce((sum, count) => sum + count, 0),
          averageQuestionsPerDay: Math.round(testCounts.reduce((sum, count) => sum + count, 0) / dates.length),
          recentDates: dates.slice(-5),
          averageRecentAccuracy: Math.round(
            correctRates.slice(-5).reduce((sum, rate) => sum + rate, 0) / Math.min(5, correctRates.length)
          ),
          consistency: dates.length >= 3 ? '継続的' : '開始したばかり',
          isImproving: correctRates[correctRates.length - 1] > correctRates[0],
          highestAccuracy: Math.round(Math.max(...correctRates)),
        };

        setAnalysisData(analysisData);
        setChartData({
          labels: dates,
          datasets: [
            {
              type: 'line',
              label: '正答率 (%)',
              data: correctRates,
              borderColor: 'rgba(216, 112, 147, 1)',
              backgroundColor: 'rgba(216, 112, 147, 0.2)',
              borderWidth: 2,
              yAxisID: 'y',
              tension: 0.3,
            },
            {
              type: 'bar',
              label: 'テスト回数',
              data: testCounts,
              backgroundColor: 'rgba(173, 216, 230, 0.6)',
              borderColor: 'rgba(173, 216, 230, 1)',
              borderWidth: 1,
              yAxisID: 'y2',
              barThickness: 30,
            },
          ],
        });

        // モチベーションメッセージの生成
        const motivationalMessage = await generateMotivationalMessage(analysisData);
        setMessage(motivationalMessage);

      } catch (error) {
        console.error('Error fetching test results:', error);
      }
    };

    fetchResults();
  }, [username]);

  if (!chartData) return <div>Loading...</div>;

  return (
    <div className="results-container">
      <h2>
        <span className="icon">📊</span>
        <span className="text">テスト結果の推移</span>
      </h2>
      
      <div className="chart-wrapper">
        <Line
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                type: 'linear',
                position: 'left',
                min: 0,
                max: 100,
                title: {
                  display: true,
                  text: '正答率 (%)',
                },
                ticks: {
                  callback: (value) => `${value}%`,
                },
                grid: {
                  display: true,
                  borderColor: 'rgba(0, 0, 0, 0.1)',
                },
              },
              y2: {
                type: 'linear',
                position: 'right',
                min: 0,
                title: {
                  display: true,
                  text: 'テスト回数',
                },
                grid: {
                  drawOnChartArea: false,
                },
              },
            },
            plugins: {
              title: {
                display: true,
                text: `${username || 'あなた'}さんの正答率とテスト回数`,
                font: {
                  size: 16,
                  weight: 'bold',
                },
              },
              legend: {
                position: 'top',
                labels: {
                  font: {
                    size: 14,
                  },
                },
              },
              tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
              },
            },
            layout: {
              padding: {
                top: 20,
                bottom: 20,
                left: 10,
                right: 10,
              },
            },
          }}
        />
      </div>

      {message && (
        <div style={{ width: '100%', maxWidth: '800px', margin: '0 auto' }}>
          <h2>
            <span className="icon">💡</span>
            <span className="text">アドバイス</span>
          </h2>
          <div style={{
            padding: '20px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            fontSize: '1rem',
            lineHeight: '1.6',
            color: '#333',
            margin: '10px auto',
          }}>
            {message}
          </div>
        </div>
      )}
    </div>
  );
};

export default Results;