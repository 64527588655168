// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <Link to="/terms" className="footer-link">
          利用規約
        </Link>
        <Link to="/privacy" className="footer-link">
          プライバシーポリシー
        </Link>
        <Link to="/cookies" className="footer-link">
          クッキーポリシー
        </Link>
        <Link to="/contactus" className="footer-link">
          お問い合わせ
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
