import React, { useEffect, useState } from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { downloadData } from 'aws-amplify/storage';
import { OpenAI } from 'openai';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

// メッセージのキャッシュ
const messageCache = new Map();
const CACHE_DURATION = 1000 * 60 * 5; // 5分

const RadarChartComponent = ({ username }) => {
  const [chartData, setChartData] = useState(null);
  const [motivationMessage, setMotivationMessage] = useState('');

  const generateMotivationMessage = async (categoryAccuracy) => {
    const cacheKey = `${username}-${JSON.stringify(categoryAccuracy)}`;
    const cachedResult = messageCache.get(cacheKey);
    
    if (cachedResult && (Date.now() - cachedResult.timestamp) < CACHE_DURATION) {
      return cachedResult.message;
    }

    // 最高と最低のカテゴリを特定
    const categories = Object.entries(categoryAccuracy);
    const sortedCategories = [...categories].sort(([, a], [, b]) => b - a);
    const highestCategory = sortedCategories[0];
    const lowestCategory = sortedCategories[sortedCategories.length - 1];
    
    // 平均正答率を計算
    const averageAccuracy = Math.round(
      Object.values(categoryAccuracy).reduce((sum, val) => sum + val, 0) / Object.values(categoryAccuracy).length
    );

    try {
      const response = await openai.chat.completions.create({
        model: "gpt-3.5-turbo-instruct",
        messages: [
          {
            role: "system",
            content: `あなたは英語学習のアドバイザーです。レーダーチャートの分析結果から、学習者の強みを活かし弱点を改善するための具体的なアドバイスを提供してください。`
          },
          {
            role: "user",
            content: `
              以下のレーダーチャートデータを分析し、モチベーションを上げつつ具体的なアドバイスを含んだメッセージを生成してください：
              
              ユーザー: ${username}
              平均正答率: ${averageAccuracy}%
              最も得意な分野: ${highestCategory[0]} (${highestCategory[1]}%)
              改善が必要な分野: ${lowestCategory[0]} (${lowestCategory[1]}%)
              
              全カテゴリデータ:
              ${Object.entries(categoryAccuracy)
                .map(([category, accuracy]) => `${category}: ${accuracy}%`)
                .join('\n')}
              
              注意点：
              - 200文字程度で簡潔に
              - 具体的な改善アドバイスを1つ含める
              - レーダーチャートの形状を意識したコメントを含める
              - ポジティブな表現を使用
            `
          }
        ],
        max_tokens: 200,
        temperature: 0.7,
      });

      const message = response.choices[0].message.content;
      messageCache.set(cacheKey, { message, timestamp: Date.now() });
      return message;

    } catch (error) {
      console.error('Error generating motivation message:', error);
      // フォールバックメッセージ
      return `${username}さん、${highestCategory[0]}が特に強みですね！
              ${lowestCategory[0]}も少しずつ改善していきましょう。
              バランスの取れた学習を心がけましょう！`;
    }
  };

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const downloadResult = await downloadData({
          path: 'public/quiz-results.json',
          options: { cacheControl: 'no-cache' },
        }).result;

        const resultsText = await downloadResult.body.text();
        const results = JSON.parse(resultsText);
        const userResults = results.filter(result => result.username === username);
        const categoryAccuracy = calculateCategoryAccuracy(userResults);

        setChartData({
          labels: Object.keys(categoryAccuracy),
          datasets: [
            {
              label: '正答率 (%)',
              data: Object.values(categoryAccuracy),
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: 'rgb(54, 162, 235)',
              borderWidth: 2,
              pointBackgroundColor: 'rgb(54, 162, 235)',
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: 'rgb(54, 162, 235)'
            },
          ],
        });

        // モチベーションメッセージを生成
        const message = await generateMotivationMessage(categoryAccuracy);
        setMotivationMessage(message);
      } catch (error) {
        console.error('Error fetching quiz results:', error);
      }
    };

    fetchResults();
  }, [username]);

  const calculateCategoryAccuracy = (userResults) => {
    const categoryCounts = {};
    userResults.forEach(result => {
      const category = result.options.find(opt => opt.word === result.correctAnswer)?.category;
      if (category) {
        if (!categoryCounts[category]) {
          categoryCounts[category] = { correct: 0, total: 0 };
        }
        categoryCounts[category].total += 1;
        if (result.isCorrect) {
          categoryCounts[category].correct += 1;
        }
      }
    });

    const categoryAccuracy = {};
    for (const category in categoryCounts) {
      const { correct, total } = categoryCounts[category];
      if (total > 0) {
        categoryAccuracy[category] = Math.round((correct / total) * 100);
      }
    }
    return categoryAccuracy;
  };

  if (!chartData) return <div>Loading...</div>;

  return (
    <div className="radar-chart-container">
      <h2>
        <span className="icon">📈</span>
        <span className="text">レーダーチャートの表示</span>
      </h2>
      <div style={{ width: '400px', height: '400px', margin: 'auto' }}>
        <Radar
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              r: {
                beginAtZero: true,
                min: 0,
                max: 100,
                ticks: {
                  stepSize: 20,
                  callback: (value) => `${value}%`,
                },
                pointLabels: {
                  font: {
                    size: 12,
                    weight: 'bold',
                  },
                  color: 'rgba(0, 0, 0, 0.8)',
                },
              },
            },
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: `${username || 'あなた'}さんの今までの回答の結果`,
                font: {
                  size: 16,
                  weight: 'bold',
                },
              },
            },
          }}
        />
      </div>
      {/* モチベーションメッセージの表示 */}
      {motivationMessage && (
        <div className="motivation-message" style={{
          margin: '20px auto',
          padding: '15px',
          maxWidth: '600px',
          backgroundColor: '#f8f9fa',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          fontSize: '1rem',
          lineHeight: '1.5',
          color: '#333'
        }}>
          <span className="icon" role="img" aria-label="motivation">💡 </span>
          {motivationMessage}
        </div>
      )}
    </div>
  );
};

export default RadarChartComponent;