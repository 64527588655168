// src/App.js
import React, { useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Navigate, Link, useLocation } from 'react-router-dom';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import MainMenu from './components/MainMenu.js';
import Search from './components/Search.js';
import Quiz from './components/Quiz.js';
import Results from './components/Results.js';
import RadarChart from './components/RadarChart.js';
import ContactUs from './components/ContactUs.js';
import Terms from './pages/Terms.js';
import Privacy from './pages/Privacy.js';
import Cookies from './pages/Cookies.js';
import AuthPage from './components/AuthPage.js';
import Footer from './components/Footer.js';
import './App.css';

// GA4測定IDを環境変数から取得（.envにREACT_APP_GA_MEASUREMENT_IDを設定）
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';

// ページビューを送信するコンポーネント
const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', GA_MEASUREMENT_ID, {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);

  return null;
};

const CustomAppBar = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  return (
    <AppBar position="static" style={{ backgroundColor: '#2c3e50', color: '#ecf0f1' }}>
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* タイトル部分 */}
        <Button
          component={Link}
          to="/"
          variant="text"
          style={{
            textTransform: 'none',
            textDecoration: 'none',
            color: 'inherit',
            padding: 0,
            minWidth: 0,
          }}
        >
          <Typography
            variant="h5"
            style={{ fontWeight: 'bold', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)' }}
          >
            英語365単語帳
          </Typography>
        </Button>
        {/* サインイン/サインアウトボタン */}
        {user ? (
          <Button
            variant="outlined"
            onClick={signOut}
            style={{
              borderColor: '#ecf0f1',
              color: '#ecf0f1',
              padding: '0.5rem 1rem',
              borderRadius: '4px',
              transition: 'background-color 0.3s, color 0.3s',
            }}
            onMouseOver={(e) => {
              e.target.style.backgroundColor = '#34495e';
              e.target.style.color = '#ffffff';
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = 'transparent';
              e.target.style.color = '#ecf0f1';
            }}
          >
            Sign Out
          </Button>
        ) : (
          <Button
            variant="outlined"
            href="/login"
            style={{
              borderColor: '#ecf0f1',
              color: '#ecf0f1',
              padding: '0.5rem 1rem',
              borderRadius: '4px',
              transition: 'background-color 0.3s, color 0.3s',
            }}
            onMouseOver={(e) => {
              e.target.style.backgroundColor = '#34495e';
              e.target.style.color = '#ffffff';
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = 'transparent';
              e.target.style.color = '#ecf0f1';
            }}
          >
            Sign In
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

const App = () => {
  return (
    <Authenticator.Provider>
      <Router>
        {/* ページビュー追跡コンポーネント */}
        <RouteChangeTracker />
        <CustomAppBar />
        <Routes>
          {/* 認証不要のルート */}
          <Route path="/" element={<MainMenu />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          {/* 認証が必要なルート */}
          <Route
            path="/results"
            element={
              <Authenticator>
                {({ user }) => <Results username={user.username} />}
              </Authenticator>
            }
          />
          <Route
            path="/radar-chart"
            element={
              <Authenticator>
                {({ user }) => <RadarChart username={user.username} />}
              </Authenticator>
            }
          />
          <Route
            path="/search"
            element={
              <Authenticator>
                {({ user }) => <Search username={user.username} />}
              </Authenticator>
            }
          />
          {/* その他のルート */}
          <Route path="/login" element={<AuthPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </Router>
    </Authenticator.Provider>
  );
};

export default App;
